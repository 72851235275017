import React, { useState, useRef } from "react";
import { Button } from "antd";
import { CloseOutlined, PlayCircleOutlined, PauseCircleOutlined, TikTokOutlined } from "@ant-design/icons";
import { XOutlined, TwitterOutlined, LineChartOutlined, SendOutlined } from "@ant-design/icons";

import logo from "../src/assets/96FA3692-E973-4A32-9582-CC6E094764E5.jpeg";
import audioFile from "../src/assets/audio.mpeg"; // Update the path to your audio file
import 'bootstrap/dist/css/bootstrap.min.css';


const AudioPlayerComponent = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio(audioFile));

  const toggleAudio = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const closeCard = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
    // Additional functionality to close the card can be added here
  };
{/* <XOutlined /> */}
  return (
    <div className="audio-player-background">


      <div className="audio-player-container ">
        <br/>
        {/* <h1 className="text-white  text-center text-5xl mt-12 mb-4 font-extrabold font-styled yellow-blink scale-x-[2] ">PWE</h1> */}
        <h1 class="auto-color text-center mb-4">PEW</h1>


        <div className="" style={{display:'flex',justifyContent:'center'}}>
        <img src={logo} alt="App Logo" className="app-logo" />

        </div>
<div style={{display:'flex',justifyContent:'center',color:'#fff',fontSize:22}}>

Ticker : PEW

</div>
<br/>

<div 
            onClick={toggleAudio}

> 

        <h1 id="button-start text-mute"
        className="flex justify-center mt-10 text-4xl text-center text-white cursor-pointer gray-blink hover:text-gray-400 one"> 
               <span className="mr-4 note-symbol note-left text-mute">♫  </span> 
               <span
               
               className=" note text-mute" id="play-text" 
                           style={{ display: isPlaying ? "none" : "inline-block",
                             }}                           >play ▶</span>
               <span className="hidden note text-mute" id="pause-text" 
                style={{ display: isPlaying ? "inline-block" : "none",
              }} >pause ❚❚</span>
               <span className="ml-4 note-symbol note-right text-mute">♫</span></h1>
        </div>
        <br/>

        <div className="text-white text-center">
       <strong>CA:</strong> <br/>

5zgTYTDK836G2Fc4ZLQp4rsyi78pAbuDr4qaQUE1pump
        </div>
        {/* <div className="button-group">

        <Button
          style={{height:50,width:50}}

        size="large"
            // 
            shape="circle"
            icon={<TikTokOutlined  style={{fontSize:'30px',color:'black'}}/>}
            onClick={toggleAudio}
            ></Button>
          <Button
          

          size="large"
            
            shape="circle"
            icon={<PlayCircleOutlined style={{fontSize:'30px'}} />}
            onClick={toggleAudio}
            style={{ display: isPlaying ? "none" : "inline-block",
            height:50,width:50
          }}
          />
          <Button

          size="large"
            
            shape="circle"
            icon={<PauseCircleOutlined style={{fontSiz5:'28px'}}/>}
            onClick={toggleAudio}
            style={{ display: isPlaying ? "inline-block" : "none",height:50,width:50 }}
          />
          <Button
          style={{height:50,width:50}}

         
          size="large"
            // 
            shape="circle"
            icon={<TikTokOutlined  style={{fontSize:'30px'}}/>}
            onClick={toggleAudio}
          />
        </div> */}
        <br/>
        <br/>
        

        <div className="d-flex justify-content-center">
        <a target="_blank" rel="noopener noreferrer" href="https://x.com/pew4sol">
        <img className="mr-5" src=" https://www.moonmancoin.com/assets/icon-x-com-new.png" style={{ height: '48px', color: 'black' }}/>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://t.me/pew4solana">
          <img className="mr-5" src=" https://www.moonmancoin.com/assets/icon-telegram-new-2.png" style={{ height: '48px', color: 'black' }}/>
          </a>
        
          <a target="_blank" rel="noopener noreferrer" href="https://www.dextools.io/app/en/solana/pair-explorer/3gG9aTPX9vRgMvKnby5EBjLPAPSHbzX7ub3NbidTYF3B?t=1717336471368">
          <img className="mr-5" src="https://www.moonmancoin.com/assets/icon-dextools.png" style={{ height: '48px', color: 'black' }}/>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.geckoterminal.com/solana/pools/3gG9aTPX9vRgMvKnby5EBjLPAPSHbzX7ub3NbidTYF3B">
          <img className="mr-5" src="https://www.moonmancoin.com/assets/icon-coingecko.png" style={{ height: '48px', color: 'black' }}/>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://dexscreener.com/solana/3gg9atpx9vrgmvknby5ebjlpapshbzx7ub3nbidtyf3b">
          <img className="" src="          https://www.moonmancoin.com/assets/icon-dexscreener.png
" style={{ height: '48px', color: 'black' }}/>
          </a>
        </div>
      </div>

      
    </div>
  );
};

export default AudioPlayerComponent;
