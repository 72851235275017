import logo from './logo.svg';
import './App.css';
import AudioPlayerComponent from './AudioPlayerComponent';

function App() {
  return (
    <div className="App">
 
       <AudioPlayerComponent />

    </div>
  );
}

export default App;
